import React from "react"
import styled from "@emotion/styled"

const Span = styled.span`
  height: 3px;
  display: block;
`

class HorizontalRule extends React.Component {


  render() {

    return (

      <div className="flex my-4">
        <div className="flex w-1/2 sm:w-1/3 md:w-1/4">
          <div className="flex-1"><Span className="bg-pink-700"></Span></div>
          <div className="flex-1"><Span className="bg-yellow-700"></Span></div>
          <div className="flex-1"><Span className="bg-purple-700"></Span></div>
          <div className="flex-1"><Span className="bg-blue-700"></Span></div>
        </div>
        <div className="flex w-1/2 sm:w-1/3 md:w-1/4">
          <div className="flex-1"><Span className="bg-pink-700"></Span></div>
          <div className="flex-1"><Span className="bg-yellow-700"></Span></div>
          <div className="flex-1"><Span className="bg-purple-700"></Span></div>
          <div className="flex-1"><Span className="bg-blue-700"></Span></div>
        </div>
        <div className="flex hidden sm:flex sm:w-1/3 md:w-1/4">
          <div className="flex-1"><Span className="bg-pink-700"></Span></div>
          <div className="flex-1"><Span className="bg-yellow-700"></Span></div>
          <div className="flex-1"><Span className="bg-purple-700"></Span></div>
          <div className="flex-1"><Span className="bg-blue-700"></Span></div>
        </div>
        <div className="flex hidden md:flex md:w-1/4">
          <div className="flex-1"><Span className="bg-pink-700"></Span></div>
          <div className="flex-1"><Span className="bg-yellow-700"></Span></div>
          <div className="flex-1"><Span className="bg-purple-700"></Span></div>
          <div className="flex-1"><Span className="bg-blue-700"></Span></div>
        </div>
      </div>
    )
  }
}

export default HorizontalRule